import React, {Component, createRef} from 'react';
import {Dropdown} from 'primereact/dropdown';
import BlockUi from 'react-block-ui';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
// import {Column} from 'primereact/column';
import {Card} from 'primereact/card';
import {Growl} from 'primereact/growl';
import {Chips} from 'primereact/chips';
import {Calendar} from 'primereact/calendar';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import {TabView,TabPanel} from 'primereact/tabview';
import {DataView, DataViewLayoutOptions} from 'primereact/dataview';
import { Storage } from 'aws-amplify';
import { Galleria } from 'primereact/galleria';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import {CONSULTA_VIAJES, CALENDAR_LOCALE, 
    ROLES, NUM_ROWS_POR_PAGINA,
    PATHS_API,ESTADO_ENTREGA_CONFIRMADA,FINALIZADO_CONCESIONARIO, FINALIZADO_TRANSPORTISTA,CONFIG_GROWL} from '../../Utils/constants';
import {cadenas} from '../../Utils/cadenas';
import {getUsuarioLogueado } from '../../Utils/gestionSessionStorage';
import {convertirDate, convertirDateAnioMEs} from '../../Utils/fechas';
import {ItemDropDownInterface} from '../../Interfaces/ItemDropDown.interface';
import {APIGATEWAY, APIGATEWAYPDF} from '../../Api/ApiGateway';
import {ViajeInterface} from '../../Interfaces/Viaje.interface';
import { VisitaInterface } from '../../Interfaces/Visita.interface';
import { ChasisInterface } from '../../Interfaces/Chasis.interface';
import { RANGO_ANIOS } from '../../Utils/constants';

/**
 * @interface
 * Se define el estado del componente.
 */
interface State {
    tiposBusqueda: Array<ItemDropDownInterface>;
    tipoBusquedaSeleccionado: ItemDropDownInterface;
    numerosFiltro: [];
    blocking: boolean;
    fechaPartida: Date|Date[]|undefined;
    fechaLlegada: Date|Date[]|undefined;
    viajes: Array<ViajeInterface>;
    viajeSeleccionado: ViajeInterface | undefined;
    visitas: Array<VisitaInterface>;
    visitaSeleccionada: VisitaInterface | undefined;
    chasis: Array<ChasisInterface>;
    pagina: number;
    tamanioPagina: number;
    totalElementos: number;
    dialogInfoVisible: boolean;
    chasisSelected: any;
    indexActivo: number | undefined;
    dialogGaleriaVisible: boolean;
    images: Array<any>;
    responsiveOptions:Array<any>;
    dialogGaleriaEvidencias: boolean;
    dialogObservaciones: boolean;
    headerObservaciones: string;
    observacionesEntrega: string;
    razonTermino: string;
}

/**
 * @class
 * Componentes para realizar consultas de los vioajes.
 */
class Viajes extends Component<{}, State> {
    private dataTableRefViajes: any;
    private dataTableRefViajesAdminNso: any;
    private dataTableRefVisitas: any;
    private dataTableRefChasis: any;
    private growlError: any;

    /**
     * @constructor
     * @param {any} props Son las propiedades del componente-
     */
    constructor(props:any) {
        super(props);
        this.dataTableRefViajes = createRef(); 
        this.dataTableRefViajesAdminNso = createRef(); 
        this.dataTableRefVisitas = createRef(); 
        this.dataTableRefChasis = createRef(); 
        this.growlError = createRef();
        this.state = {
            viajes: [],
            viajeSeleccionado: undefined,
            visitas: [],
            visitaSeleccionada: undefined,
            chasis: [],
            tiposBusqueda: [],
            tipoBusquedaSeleccionado: {label:'', value:''},
            numerosFiltro: [],
            blocking:false,
            fechaPartida: new Date(),
            fechaLlegada: new Date(),
            pagina: 0,
            tamanioPagina: 0,
            totalElementos: 0,
            dialogInfoVisible: false,
            chasisSelected: undefined,
            indexActivo: undefined,
            dialogGaleriaVisible:false,
            images:[],
            responsiveOptions : [
                {
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '960px',
                    numVisible: 4
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
            dialogGaleriaEvidencias: false,
            dialogObservaciones: false,
            headerObservaciones: '',
            observacionesEntrega: '',
            razonTermino: '',
        }
        this.export = this.export.bind(this);
        this.buscarViajes = this.buscarViajes.bind(this);
        this.crearFormularioBusqueda = this.crearFormularioBusqueda.bind(this);
        this.crearCamposBusqueda = this.crearCamposBusqueda.bind(this);
        this.crearFechasBusqueda = this.crearFechasBusqueda.bind(this);
        this.cambiarVista = this.cambiarVista.bind(this);
        this.dataTableViajes = this.dataTableViajes.bind(this);
        this.dataTableViajesAdminNso = this.dataTableViajesAdminNso.bind(this);
        this.dataTableVisitas = this.dataTableVisitas.bind(this);
        this.dataTableChasis = this.dataTableChasis.bind(this);
        this.organizarOpcionesPorRol = this.organizarOpcionesPorRol.bind(this);
        this.buscarVisistasPorViaje = this.buscarVisistasPorViaje.bind(this);
        this.buscarChasisPorVisita = this.buscarChasisPorVisita.bind(this);
        this.obtenerPdf = this.obtenerPdf.bind(this);
        this.cargarPagina = this.cargarPagina.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.crearCamposBusquedaNumEcoFecha=this.crearCamposBusquedaNumEcoFecha.bind(this);       
        this.onModelFilterChange=this.onModelFilterChange.bind(this);
        this.generarReporteFull=this.generarReporteFull.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.thumbnailTemplate = this.thumbnailTemplate.bind(this);
        this.showGaleriaDanios= this.showGaleriaDanios.bind(this);
    }

    itemTemplate(item:any) {
        return <img src={item}  style={{ width: '100%', display: 'block' }} alt="Img" />
    }

    thumbnailTemplate(item:any) {
        return <img src={item}   style={{ width: '5%', display: 'block' }} alt="Img" />
    }
    /**
     * Evento que se ejecuta antes de renderizar el componente.
     */
    componentDidMount() {
        this.organizarOpcionesPorRol();
    }

    /**
     * Organizar las opciones de búsqueda de acuerdo al perfil logueado.
     */
    organizarOpcionesPorRol() {
        switch (getUsuarioLogueado()?.rol) {
            case ROLES.ADMINSIS:
                this.setState({
                    tiposBusqueda: [
                        //{label: cadenas.CONSULTA_VIAJES_COD_CONFIRM_LABEL, value: CONSULTA_VIAJES.COD_CONFIRM_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_TRP_LABEL, value: CONSULTA_VIAJES.NUM_TRP_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE}
                    ]
                }); 
                break;
            case ROLES.ADMINREAD:
                    this.setState({
                        tiposBusqueda: [
                            //{label: cadenas.CONSULTA_VIAJES_COD_CONFIRM_LABEL, value: CONSULTA_VIAJES.COD_CONFIRM_VALUE},
                            {label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE},
                            {label: cadenas.CONSULTA_VIAJES_NUM_TRP_LABEL, value: CONSULTA_VIAJES.NUM_TRP_VALUE},
                            {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                            {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                            {label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE},
                            {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE}
                        ]
                    }); 
                    break;
            case ROLES.ADMINDEALER:
                this.setState({
                    tiposBusqueda: [
                        //{label: cadenas.CONSULTA_VIAJES_COD_CONFIRM_LABEL, value: CONSULTA_VIAJES.COD_CONFIRM_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE}
                    ]
                }); 
                break;
            case ROLES.ADMINTRANSPORTISTA:

                this.setState({
                    tiposBusqueda: [
                        /*{label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORIGENLABEL, value: CONSULTA_VIAJES.ORIGEN},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_CONDUCTOR_LABEL, value: CONSULTA_VIAJES.CONDUCTOR},
                        {label: cadenas.CONSULTA_VIAJES_CARRIER_LABEL, value: CONSULTA_VIAJES.CARRIER},
                        {label: cadenas.CONSULTA_VIAJES_ESTATUS_LABEL, value: CONSULTA_VIAJES.ESTATUS},
                        {label: cadenas.CONSULTA_VIAJES_FECHA_INICIO, value: CONSULTA_VIAJES.FECHA_INICIO},
                        {label: cadenas.CONSULTA_VIAJES_FECHA_CONSULTA, value: CONSULTA_VIAJES.FECHA_CONSULTA},
                        {label: cadenas.CONSULTA_VIAJES_NO_UNIDADES_LABEL, value: CONSULTA_VIAJES.NO_UNIDADES}*/

                        {label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},

                    ]
                }); 
                break;
            case ROLES.SEGUROS:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_TRP_LABEL, value: CONSULTA_VIAJES.NUM_TRP_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_ORDER_ID_LABEL, value: CONSULTA_VIAJES.ORDERID_VALUE}
                    ]
                }); 
                break;
            case ROLES.ADMINNSO:
                this.setState({
                    tiposBusqueda: [
                        {label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE},
                        {label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE}
                    ]
                }); 
                break;
            case ROLES.INSPECTOR:
                this.setState({
                  tiposBusqueda: [
                    { label: cadenas.CONSULTA_VIAJES_NUM_TRK_LABEL, value: CONSULTA_VIAJES.NUM_TRK_VALUE },
                    { label: cadenas.CONSULTA_VIAJES_VIN_LABEL, value: CONSULTA_VIAJES.VIN_VALUE },
                    { label: cadenas.CONSULTA_VIAJES_FECHA_PARTIDA, value: CONSULTA_VIAJES.RANGO_FECHA_VALUE },
                    { label: cadenas.CONSULTA_VIAJES_NUM_ECONO_LABEL, value: CONSULTA_VIAJES.NUM_ECONO_VALUE },
                  ],
                }); 
                break;
        }
    }

    /**
     * Exportar los datos del datatable a formato CSV.
     * @param {any} dataTable Referencia del datatable.
     */
    export(dataTable: any) {
        dataTable.current.exportCSV();
    }
    /**
     * resetear los datos del datatable.
     * @param {any} dataTable Referencia del datatable.
     */
    resetTable(dataTable: any) {
        dataTable.current.reset();
        console.log('Se reseteo la tabla');
    }

    /**
     * Mostrar notificación de error.
     * @param {string} title Título de la notificación. 
     * @param {string} message Mensaje de la notificación.
     */
    showError(title:string, message:string) {
        this.growlError.current.show({severity: 'error', summary: title, detail: message});
    }

    /**
     * Mostrar la notificación de tipo Growl (componente del framewrok Prime React).
     * @param {string} type Tipo de la notificación. 
     * @param {string} title Título de la notificación.
     * @param {string} message Mensaje de la notificación.
     */
     showNotificacion(type:string, title:string, message:string) {
        this.growlError.current.show({severity: type, summary: title, detail: message, life:CONFIG_GROWL.LIFE});
    }

    /**
     * Definir el rol que realiza la búsqueda de los viajes.
     * @param {any} body Objeto que se envia al servidor.
     */
    definirRolBusqueda(body: any) {
        switch (getUsuarioLogueado()?.rol) {
            case ROLES.ADMINSIS:
                body.numDealer = '*';
                body.numTransportista = '*';
                break;
            case ROLES.ADMINREAD:
                body.numDealer = '*';
                body.numTransportista = '*';
                break;
            case ROLES.ADMINDEALER:
                body.numDealer = (getUsuarioLogueado()?.codigoEmpresa as string);
                body.numTransportista = '*';
                break;
            case ROLES.ADMINTRANSPORTISTA:
                body.numDealer = '*';
                body.numTransportista = (getUsuarioLogueado()?.codigoEmpresa as string);
                break;
        }
        return body;
    }

    /**
     * Definir las opciones para realizar la búsqueda de los viajes.
     * @param {any} body Objeto que se envia al servidor.
     */
    definirOpcionesBusqueda(body: any) {
        switch (this.state.tipoBusquedaSeleccionado.value) {
            case CONSULTA_VIAJES.FECHA_INICIO:
                body.fechaInicio = convertirDateAnioMEs(this.state.fechaPartida as Date);
                break;
            case CONSULTA_VIAJES.FECHA_CONSULTA:
                body.fechaConsulta = convertirDateAnioMEs(this.state.fechaPartida as Date);
                break;
            case CONSULTA_VIAJES.RANGO_FECHA_VALUE:
                body.fechaPartida = convertirDateAnioMEs(this.state.fechaPartida as Date);
                console.log(body.fechaPartida);
                body.fechaLlegada = convertirDate(this.state.fechaLlegada as Date);
                break;
            case CONSULTA_VIAJES.NUM_TRK_VALUE:
                body.trks = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.CARRIER:
                body.carriers = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.ORIGEN:
                body.origen = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.CONDUCTOR:
                body.conductores = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.ESTATUS:
                body.estatus = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.NUM_TRP_VALUE:
                body.trps = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.NO_UNIDADES:
                body.numsUnidades = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.COD_CONFIRM_VALUE:
                body.codigos = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.VIN_VALUE:
                body.vins = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.ORDERID_VALUE:
                body.ordersId = this.state.numerosFiltro;
                break;
            case CONSULTA_VIAJES.NUM_ECONO_VALUE:
                body.numsEconomico = this.state.numerosFiltro;
                body.fechaPartida = convertirDateAnioMEs(this.state.fechaPartida as Date);
                break;
        }
        return body;
    }

    /**
     * Crear el objeto body que se envia al servidor
     * para realizar la búsqueda de viajes.
     */
    crearBodyBusqueda() {
        let body = {
            fechaPartida: '',
            fechaLlegada: '',
            fechaInicio: '',
            fechaConsulta: '',
            rol: getUsuarioLogueado()?.rol,
            numDealer: '',
            numTransportista: '',
            vins: [],
            trks: [],
            trps: [],
            origen: [],
            conductores: [],
            carriers: [],
            estatus: [],
            codigos: [],
            numsUnidades: [],
            numsEconomico: [],
            ordersId: [],
            pagina: 0,
            tamanioPagina: NUM_ROWS_POR_PAGINA,
            filtrosTabla:[],
            daniosFaltantes:'',
        };
        body = this.definirRolBusqueda(body);
        body = this.definirOpcionesBusqueda(body);
        return body;
    }

    /**
     * Buscar viajes con párametros de búsqueda.
     * @param {React.FormEvent} event Evento del formulario.
     */
    buscarViajes(event:React.FormEvent) {
        event.preventDefault();
        // validacion
        if (this.state.tipoBusquedaSeleccionado.value !== CONSULTA_VIAJES.RANGO_FECHA_VALUE 
            && this.state.tipoBusquedaSeleccionado.value !== CONSULTA_VIAJES.FECHA_INICIO
            && this.state.tipoBusquedaSeleccionado.value !== CONSULTA_VIAJES.FECHA_CONSULTA
            && this.state.numerosFiltro.length === 0) {
            this.showError(cadenas.ERROR_TITULO_VALIDACION, cadenas.CONSULTA_VIAJES_ERROR_VALIDACION_NUMS+this.state.tipoBusquedaSeleccionado.label);
            return;
        }
        this.setState({
            blocking: true
        });
        console.log("Viajes -> buscarViajes -> this.crearBodyBusqueda()", JSON.stringify(this.crearBodyBusqueda()))
        APIGATEWAY.post(PATHS_API.VIAJES_CONSULTA, this.crearBodyBusqueda() )
        .then(response => {
            console.log("Viajes -> buscarViajes -> response", response)
            this.setState({
                blocking: false,
                viajes: response.datos,
                totalElementos: response.totalElementos,
                pagina: response.pagina*NUM_ROWS_POR_PAGINA // datatable usa como first, el num del row con cual inicia la pagina seleccionada
               
            });            
        })
        .catch(error => {
            console.log("Viajes -> buscarViajes -> error: ", error)
            this.setState({
                blocking:false,
                viajes: []
                
            });
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VIAJES);
        });
    }
    /**
     * Cargar los registros de la página seleccionada en el datatable.
     * @param {any} event Evento de la página seleccionada.
     */
    cargarPagina(event: any) {
        this.setState({
            blocking: true
        });
        const body = this.crearBodyBusqueda();
        body.pagina = event.page;        
        console.log("Viajes -> cargarPagina -> event", event)
        console.log("Viajes -> cargarPagina -> body", JSON.stringify(body))
        APIGATEWAY.post(PATHS_API.VIAJES_CONSULTA, body )
        .then(response => {
            console.log("Viajes -> cargarPagina -> response", response)
            this.setState({
                blocking: false,
                pagina: response.pagina*NUM_ROWS_POR_PAGINA, // datatable usa como first, el num del row con cual inicia la pagina seleccionada
                viajes: response.datos
            });
        })
        .catch(error => {
            this.setState({
                blocking:false,
                viajes: []
            });
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VIAJES);
        });
    }

    /**
     * Realizar la búsqueda de visitas por el viaje seleccionado.
     * @param {any} viaje Instancia del viaje seleccionado en el datatable de viajes.
     */
    buscarVisistasPorViaje(viaje: any) {
        this.setState({
            blocking: true
        });
        const body = {
            orderId: viaje.orderId
        };
        console.log("Viajes -> buscarVisistasPorViaje -> body", body)
        APIGATEWAY.post(PATHS_API.VISITAS_CONSULTA, body )
        .then(response => {
        console.log("Viajes -> buscarVisistasPorViaje -> response", response)
            this.setState({
                viajeSeleccionado: viaje,
                blocking: false,
                visitas: response
            });
        })
        .catch(error => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VISITAS);
        });
    }

    /**
     * Realizar la búsqueda de chasis por la visita seleccionada.
     * @param {any} visita Instancia de la visita seleccionada en el datatable de visitas.
     */
    buscarChasisPorVisita(visita: any) {
        this.setState({
            blocking: true
        });
        const body = {
            orderId: this.state.viajeSeleccionado?.orderId, 
            numDealer: visita.concesionario
        };
        APIGATEWAY.post(PATHS_API.CHASIS_CONSULTA, body )
        .then(response => {
            console.log("Viajes -> buscarChasisPorVisita -> response", response)
            this.setState({
                visitaSeleccionada: visita,
                blocking: false,
                chasis: response
            });
        })
        .catch(error => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_CHASIS);
        });
    }

    /**
     * Creación de los campos tipo fecha para realizar la búsqueda.
     */
    crearFechasBusqueda() {
        return (
        <div>
            <div className="p-field">
                <label htmlFor="fechaPartida">{cadenas.CONSULTA_VIAJES_FORM_FECHA_MES_ANIO}: *</label>
                <Calendar id="fechaPartida" value={this.state.fechaPartida} 
                    locale={CALENDAR_LOCALE}
                    onChange={(e) => this.setState({fechaPartida: e.value})}
                    required={true} showTime={false} 
                    monthNavigator={true} yearNavigator={true} yearRange={RANGO_ANIOS}
                    view="month" dateFormat="mm/yy"
                />
            </div>            
            {/** 
             <div className="p-field">
                <label htmlFor="fechaLlegada">{cadenas.CONSULTA_VIAJES_FORM_FECHA_LLEGADA}: *</label>
                <Calendar id="fechaLlegada" inputId="fechaLlegadaInput" value={this.state.fechaLlegada} 
                    locale={CALENDAR_LOCALE} minDate={this.state.fechaPartida as Date}
                    onChange={(e) => this.setState({fechaLlegada: e.value})}
                    required={true} showTime={true}
                    monthNavigator={true} yearNavigator={true} yearRange={RANGO_ANIOS}
                />
            </div>*/}
        </div>
        );
    }

    /**
     * Creación del campo tipo array de textos para realizar la búsqueda.
     */
    crearCamposBusqueda() {
        return (
        <div className="p-field">
            <label htmlFor="numerosFiltro">{cadenas.CONSULTA_VIAJES_FORM_NUMEROS} {this.state.tipoBusquedaSeleccionado.label}: *</label>
            <Chips id="numerosFiltro" value={this.state.numerosFiltro} separator=","
                max={CONSULTA_VIAJES.MAX_NUMEROS_FILTRO} allowDuplicate={false}
                onChange={(e) => this.setState({numerosFiltro: e.value})}
            />
            <small id="username-help">{cadenas.CONSULTA_VIAJES_FORM_NUMEROS_HELPER}</small>
        </div>
        );
    }
    /**
     * Creación del campo tipo array de textos para realizar la búsqueda.
     */
    crearCamposBusquedaNumEcoFecha() {
        return (
        <div>   
        <div className="p-field">
                <label htmlFor="fechaPartida2">{cadenas.CONSULTA_VIAJES_FORM_FECHA_MES_ANIO}: *</label>
                <Calendar id="fechaPartida2" value={this.state.fechaPartida} 
                    locale={CALENDAR_LOCALE}
                    onChange={(e) => this.setState({fechaPartida: e.value})}
                    required={true} showTime={false} 
                    monthNavigator={true} yearNavigator={true} yearRange={RANGO_ANIOS}
                    view="month" dateFormat="mm/yy"
                />
            </div>     
        <div className="p-field">
            <label htmlFor="numerosFiltro">{cadenas.CONSULTA_VIAJES_FORM_NUMEROS} {this.state.tipoBusquedaSeleccionado.label}: *</label>
            <Chips id="numerosFiltro" value={this.state.numerosFiltro} separator=","
                max={CONSULTA_VIAJES.MAX_NUMEROS_FILTRO} allowDuplicate={false}
                onChange={(e) => this.setState({numerosFiltro: e.value})}
            />
            <small id="username-help">{cadenas.CONSULTA_VIAJES_FORM_NUMEROS_HELPER}</small>
        </div>
        
        </div>
        );
    }

    /**
     * Creación del formulario para realizar la búsqueda de viajes.
     */
    crearFormularioBusqueda() {
        return (
        <form onSubmit={this.buscarViajes}>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="tipoBusquedaSeleccionado">{cadenas.CONSULTA_VIAJES_FORM_BUSCAR}: *</label>
                    <Dropdown 
                        value={this.state.tipoBusquedaSeleccionado.value} options={this.state.tiposBusqueda} 
                        onChange={(e) => {
                            let tipoSeleccionado = this.state.tiposBusqueda.find(tb => tb.value === e.value);
                            this.setState({tipoBusquedaSeleccionado: tipoSeleccionado!})
                        }} required={true}
                        placeholder={cadenas.SELECT_SELECCIONA} 
                    />
                </div>
                {(this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.RANGO_FECHA_VALUE
                || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.FECHA_INICIO 
                || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.FECHA_CONSULTA)
                    &&
                    this.crearFechasBusqueda()
                }
                {(this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.COD_CONFIRM_VALUE
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.CONDUCTOR
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.ORIGEN
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.CARRIER
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.ESTATUS   
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.NO_UNIDADES   
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.NUM_TRK_VALUE 
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.NUM_TRP_VALUE                  
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.ORDERID_VALUE                   
                    || this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.VIN_VALUE) &&
                    this.crearCamposBusqueda()
                }
                {(this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.NUM_ECONO_VALUE
                    ) &&
                    this.crearCamposBusquedaNumEcoFecha()
                }
            </div>
            <Button label={cadenas.CONSULTA_VIAJES_BTN_BUSCAR} 
                className="p-button p-button-success p-button-rounded"
                icon="pi pi-search" 
                iconPos="left"
            />
        </form>
        );
    }

    /**
     * @returns Datatable para mostrar el resultado de la búsqueda.
     */
    dataTableViajes() {
        const headerDatatable = <div className="p-grid">
            <div className="p-col-6" style={{textAlign:"left"}} >
                <h2>{cadenas.CAPTURA_ONLINE_TITULO_DATATABLE_VIAJES}</h2>
            </div>
            <div className="p-col-6" style={{textAlign:"right"}} >
                <Button type="button" icon="pi pi-external-link" iconPos="left" style={{marginRight: '.5em'}} 
                    label={cadenas.BTN_EXPORTAR_CSV} onClick={() => this.export(this.dataTableRefViajes)} 
                />
                {
                    this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.RANGO_FECHA_VALUE &&
                <Button tooltip={cadenas.REPORTE_FULL_DANIOS} tooltipOptions={{position: 'left'}} type="button" 
                    icon="pi pi-file-excel" className="p-button-success" style={{marginRight: '.5em'}} 
                    disabled={this.state.tipoBusquedaSeleccionado.value!==CONSULTA_VIAJES.RANGO_FECHA_VALUE}                    
                    onClick={() => {                        
                        this.generarReporteFull('danios');                        
                    }}/>
                }
                {  
                 this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.RANGO_FECHA_VALUE &&  
                <Button tooltip={cadenas.REPORTE_FULL_FALTANTES} tooltipOptions={{position: 'left'}} type="button" 
                    icon="pi pi-file-excel" className="p-button-success" style={{marginRight: '.5em'}} 
                    disabled={this.state.tipoBusquedaSeleccionado.value!==CONSULTA_VIAJES.RANGO_FECHA_VALUE}                    
                    onClick={() => {                        
                        this.generarReporteFull('Faltantes');                        
                    }}/> 
               }       
            </div>           
        </div>;
        
        return (
            <div className="p-col-12">
                <DataTable value={this.state.viajes}  paginator={true} 
                    rows={NUM_ROWS_POR_PAGINA}                                    
                    header={headerDatatable}  responsive={true}
                    ref={this.dataTableRefViajes}                   
                    emptyMessage={cadenas.DATATABLE_VACIO}
                    selection={this.state.viajeSeleccionado} 
                    onSelectionChange={e => this.buscarVisistasPorViaje(e.value)}
                    //totalRecords={this.state.totalElementos}                    
                    lazy={false}
                    //loading={this.state.blocking}
                    //first={this.state.pagina} 
                    //onPage={this.cargarPagina}
                    //onFilter={this.onModelFilterChange}                                                         
                >
                    <Column selectionMode="single" style={{width:'3em'}} />                
                    <Column field="orderId" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_ORDERID} filter={true} />
                    <Column field="numTrk" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_TRK} filter={true}/>
                    <Column field="compound" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_COMPOUND} filter={true} sortable={true} />
                    <Column field="numEconomico" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_NUMECONOMICO} filter={true} />
                    <Column field="driverName" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_CONDUCTOR} filter={true} />
                    <Column field="carrier" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_CARRIER} filter={true} sortable={true} />
                    <Column field="estatusViaje" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_ESTATUS} filter={true} sortable={true} />
                    <Column field="leftDate" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_FECHA_REGISTRO} filter={true} sortable={true} sortField="leftDateLong"/>
                    <Column field="fechaConsultado"
                        body={(rowData:any, column:any) => 
                        this.regresarFechaLocal(rowData)
                        } header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_FECHA_CONSULTA}  filter={true} sortable={true} sortField="fhConsultado"/>
                    <Column field="totalAmountOfUnits" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_FECHA_NUM_UNIDADES} filter={true} sortable={true} />
                </DataTable>
            </div>
        );
       
    }

    /**
     * @returns Datatable para mostrar el resultado de la búsqueda.
     */
    dataTableViajesAdminNso() {
        const headerDatatable = <div className="p-grid">
            <div className="p-col-6" style={{textAlign:"left"}} >
                <h2>{cadenas.CAPTURA_ONLINE_TITULO_DATATABLE_VIAJES}</h2>
            </div>
            <div className="p-col-6" style={{textAlign:"right"}} >
                <Button type="button" icon="pi pi-external-link" iconPos="left" style={{marginRight: '.5em'}} 
                    label={cadenas.BTN_EXPORTAR_CSV} onClick={() => this.export(this.dataTableRefViajesAdminNso)} 
                />
                {
                    this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.RANGO_FECHA_VALUE &&
                <Button tooltip={cadenas.REPORTE_FULL_DANIOS} tooltipOptions={{position: 'left'}} type="button" 
                    icon="pi pi-file-excel" className="p-button-success" style={{marginRight: '.5em'}} 
                    disabled={this.state.tipoBusquedaSeleccionado.value!==CONSULTA_VIAJES.RANGO_FECHA_VALUE}                    
                    onClick={() => {                        
                        this.generarReporteFull('danios');                        
                    }}/>
                }
                {  
                 this.state.tipoBusquedaSeleccionado.value === CONSULTA_VIAJES.RANGO_FECHA_VALUE &&  
                <Button tooltip={cadenas.REPORTE_FULL_FALTANTES} tooltipOptions={{position: 'left'}} type="button" 
                    icon="pi pi-file-excel" className="p-button-success" style={{marginRight: '.5em'}} 
                    disabled={this.state.tipoBusquedaSeleccionado.value!==CONSULTA_VIAJES.RANGO_FECHA_VALUE}                    
                    onClick={() => {                        
                        this.generarReporteFull('Faltantes');                        
                    }}/> 
               }       
            </div>           
        </div>;
        
        return (
            <div className="p-col-12">
                <DataTable value={this.state.viajes}  paginator={true} 
                    rows={NUM_ROWS_POR_PAGINA}                                    
                    header={headerDatatable}  responsive={true}
                    ref={this.dataTableRefViajesAdminNso}                   
                    emptyMessage={cadenas.DATATABLE_VACIO}
                    selection={this.state.viajeSeleccionado} 
                    onSelectionChange={e => this.buscarVisistasPorViaje(e.value)}
                    //totalRecords={this.state.totalElementos}                    
                    lazy={false}
                    //loading={this.state.blocking}
                    //first={this.state.pagina} 
                    //onPage={this.cargarPagina}
                    //onFilter={this.onModelFilterChange}                                                         
                >
                    <Column selectionMode="single" style={{width:'3em'}} />                                    
                    <Column field="compound" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_COMPOUND} filter={true} sortable={true} />
                    <Column field="numEconomico" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_NUMECONOMICO} filter={true} />
                    <Column field="driverName" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_CONDUCTOR} filter={true} />
                    <Column field="carrier" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_CARRIER} filter={true} sortable={true} />
                    <Column field="estatusViaje" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_ESTATUS} filter={true} sortable={true} />
                    <Column field="leftDate" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_FECHA_REGISTRO} filter={true} sortable={true} sortField="leftDateLong"/>
                    <Column field="fechaConsultado"
                        body={(rowData:any, column:any) => 
                        this.regresarFechaLocal(rowData)
                        } header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_FECHA_CONSULTA}  filter={true} sortable={true} sortField="fhConsultado"/>
                    <Column field="totalAmountOfUnits" header={cadenas.CAPTURA_ONLINE_VIAJES_DATA_FECHA_NUM_UNIDADES} filter={true} sortable={true} />
                </DataTable>
            </div>
        );
       
    }

    regresarDisplay(){        
        return getUsuarioLogueado()?.rol != ROLES.ADMINNSO ? "" : "none"
    }

    regresarFechaLocal(rowData:any){
        let fechaLocal = "";
        if(rowData.fhConsultado !== undefined)
            fechaLocal = new Date(rowData.fhConsultado*1).toLocaleString("es-MX",{year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', timeZone:'America/Mexico_City'})
        rowData.fechaConsultado = fechaLocal;
        return fechaLocal;
    }

    regresarFechaLlegada(rowData:any){
        let fechaLocal = "";
        if(rowData.fechaLlegada !== "")
            fechaLocal = new Date(rowData.fechaLlegada).toLocaleString("es-MX",{year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', timeZone:'America/Mexico_City'})
        rowData.fechaLlegadaLocal = fechaLocal;
        return fechaLocal;
    }

    /**
     * @returns Datatatable para mostrar las visitas del viaje encontrado.
     */
    dataTableVisitas() {
        const headerDatatable = <div className="p-grid">
            <div className="p-col-4" style={{textAlign:"left"}} >
                <Button type="button" icon="pi pi-arrow-left" 
                    iconPos="left" label={cadenas.BTN_REGRESAR} 
                    onClick={
                        () => this.cambiarVista(CONSULTA_VIAJES.VISTA_VISITAS)
                    } 
                />
            </div>
            <div className="p-col-4" style={{textAlign:"center"}}>
                <h2>{cadenas.CAPTURA_ONLINE_TITULO_DATATABLE_VISITAS}: {this.state.viajeSeleccionado!.orderId}</h2>
            </div>
            <div className="p-col-4" style={{textAlign:"right"}} >
                <Button type="button" icon="pi pi-external-link" iconPos="left" label={cadenas.BTN_EXPORTAR_CSV} onClick={() => this.export(this.dataTableRefVisitas)} />
            </div>
        </div>;
         const actionDatatable = (rowData:any, column:any) => {
             console.log("rowData", rowData)
            return <div>
                <Button tooltip={cadenas.REV} tooltipOptions={{position: 'top'}} type="button" 
                    icon="pi pi-file-pdf" className="p-button-success" style={{marginRight: '.5em'}} 
                    disabled={rowData.estatusEntrega !== ESTADO_ENTREGA_CONFIRMADA &&  rowData.estatusEntrega !== FINALIZADO_CONCESIONARIO && rowData.estatusEntrega !== FINALIZADO_TRANSPORTISTA}                    
                    onClick={(e) => {                        
                        this.obtenerPdf(rowData,this.state.viajeSeleccionado!.orderId);
                        
                    }}/>
                <button type="button"
                    className="p-button p-component p-button p-button-rounded p-button-success p-button-icon-only"
                    style={{marginRight: '.5em'}}
                    //disabled={!rowData.observaciones}
                    title={cadenas.CAPTURA_ONLINE_BTN_OBSERVACIONES}
                    onClick={() => {
                        this.showDialogObservaciones(rowData)
                    }}
                >
                        <span className="p-c p-button-icon-left">
                            <FontAwesomeIcon icon="comment-alt" />
                        </span>
                        <span className="p-button-text p-c">p-btn</span>
                </button>
                {
                    (rowData.longitud === "0" && rowData.latitud === "0") &&
                <Button tooltip={cadenas.CONSULTA_VIAJES_EVIDENCIAS} tooltipOptions={{position: 'top'}} type="button" 
                    icon="pi pi-image" className="p-button-success" style={{marginRight: '.5em'}}                  
                    onClick={(e) => {                        
                        this.showGaleriaEvidencias(rowData,this.state.viajeSeleccionado!.orderId);
                    }}/>
                }
                {
                    (getUsuarioLogueado()?.rol === ROLES.ADMINDEALER && getUsuarioLogueado()?.codigoEmpresa === rowData.concesionario) &&
                <Button tooltip={cadenas.CONSULTA_VIAJES_RESEND} tooltipOptions={{position: 'top'}} type="button" 
                    icon="pi pi-envelope" className="p-button-success" style={{marginRight: '.5em'}}                  
                    onClick={(e) => {                        
                        //this.showGaleriaEvidencias(rowData,this.state.viajeSeleccionado!.orderId);
                        console.log("debo reenviar codigos");
                        this.resendCode(rowData.concesionario, this.state.viajeSeleccionado!.orderId);
                    }}/>
                }
            </div>;
        }
        const footerDialogObservaciones = (
            <div>
                <Button type="button" className="p-button p-button-success" label="Aceptar" icon="pi pi-check"
                    onClick={(e) => {
                        this.setState({dialogObservaciones: false, headerObservaciones: '', observacionesEntrega:'', razonTermino:''})
                    }}/>
            </div>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <DataTable value={this.state.visitas} paginator={true} rows={NUM_ROWS_POR_PAGINA} 
                        header={headerDatatable} ref={this.dataTableRefVisitas}
                        responsive={true} emptyMessage={cadenas.DATATABLE_VACIO}
                        selection={this.state.viajeSeleccionado} 
                        onSelectionChange={e => this.buscarChasisPorVisita(e.value)}
                    >
                        <Column selectionMode="single" style={{width:'3em'}}/>
                        <Column field="concesionario" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_CONCESIONARIA} filter={true} sortable={true} />
                        <Column field="estatusEntrega" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_ESTATUS_ENTREGA} filter={true} sortable={true} />
                        <Column field="fechaLlegadaLocal"
                        body={(rowData:any, column:any) => 
                        this.regresarFechaLlegada(rowData)
                        } header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_FECHA_LLEGADA}  filter={true} sortable={true} sortField="fechaLlegada"/>
                        {/*<Column field="fechaLlegadaSyncrotessEnvio" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_FECHA_RECEPCION} filter={true} sortable={true} />*/}
                        <Column field="latitud" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_UBICACION_LAT} filter={true} sortable={true} />
                        <Column field="longitud" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_UBICACION_LNG} filter={true} sortable={true} />
                        <Column field="fechaFinalizado" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_FECHA_FINALIZACION} filter={true} sortable={true} />
                        <Column field="finalizadoPor" header={cadenas.CAPTURA_ONLINE_VISITAS_DATA_FINALIZADO_POR} filter={true} sortable={true} />
                        <Column body={actionDatatable} style={{textAlign:'center', width: '15em'}}/>
                    </DataTable>
                </div>
                <Dialog header='Evidencias' visible={this.state.dialogGaleriaEvidencias} position="center"
                    blockScroll={true} //footer={footerDialogSiniestro} maximized={true}
                    style={{ width: '50vw' }} modal={true}
                    onHide={() => this.setState({ dialogGaleriaEvidencias: false,
                        images:[] })}>
                    <div>
                        <div>{
                            this.state.images.length <= 0 ?
                                <div className="card">
                                    <Card >
                                        <div className="p-text-nowrap p-text-truncate p-text-bold" >
                                            No se agregaron imagenes para esta entrega
                                        </div>
                                   </Card>
                                </div>
                                :
                                <div className="card">
                                    <Galleria value={this.state.images} responsiveOptions={this.state.responsiveOptions} numVisible={3} circular style={{ maxWidth: '800px' }}
                                        //thumbnailItemTemplate={this.thumbnailTemplate}
                                        previewItemTemplate={this.itemTemplate}
                                        showThumbnails={false}
                                        showIndicators={true}
                                    />
                                </div>
                        }</div>
                    </div>
                </Dialog>
                <Dialog header={this.state.headerObservaciones} visible={this.state.dialogObservaciones} position="center"
                            blockScroll={true} footer={footerDialogObservaciones} 
                            style={{width: '50vw'}} modal={true}
                            onHide={() => this.setState({dialogObservaciones: false})}>
                            <div className="p-fluid">
                                <div className="p-field p-grid">                                    
                                    <Card >
                                        <label style={{paddingTop:10, fontWeight:'bold'}}>Observaciones Entrega</label>
                                        <div className="p-text-nowrap p-text-truncate p-text-bold" >
                                            {this.state.observacionesEntrega}
                                        </div>
                                        <label style={{paddingTop:20, fontWeight:'bold'}}>Razón Termino</label>
                                        <div className="p-text-nowrap p-text-truncate p-text-bold" >
                                            {this.state.razonTermino}
                                        </div>
                                   </Card>
                                </div>
                            </div>
                </Dialog>
            </div>
        );
    }
    
    /**
     * @returns Datatatable para mostrar los chasis de la visita seleccionada.
     */
    dataTableChasis() {
        const headerDatatable = <div className="p-grid">
                                <div className="p-col-4" style={{textAlign:"left"}} >
                                    <Button type="button" icon="pi pi-arrow-left" 
                                        iconPos="left" label={cadenas.BTN_REGRESAR} 
                                        onClick={
                                            () => this.cambiarVista(CONSULTA_VIAJES.VISTA_CHASIS)
                                        } 
                                    />
                                </div>
                                <div className="p-col-4" style={{textAlign:"center"}}>
                                    <h2>{cadenas.CAPTURA_ONLINE_TITULO_DATATABLE_VINS}: {this.state.visitaSeleccionada!.concesionario}</h2>
                                </div>
                                <div className="p-col-4" style={{textAlign:"right"}} >
                                    <Button type="button" icon="pi pi-external-link" iconPos="left" label={cadenas.BTN_EXPORTAR_CSV} onClick={() => this.export(this.dataTableRefChasis)} />
                                </div>
                            </div>;
        const actionDatatable = (rowData:any, column:any) => {
            return <div>
                <Button tooltip={cadenas.CONSULTA_VIAJES_INFORMACION_CHASIS} tooltipOptions={{position: 'top'}} type="button" 
                    icon="pi pi-info-circle" className="p-button-success" style={{marginRight: '.5em'}} 
                    onClick={(e) => {                        
                        console.log("rowData Chasis", rowData)
                        this.setState({chasisSelected: rowData ,dialogInfoVisible:true})
                        //console.log("danos", JSON.parse(rowData.danios))
                        //console.log("faltantes", JSON.parse(rowData.faltantes))
                        
                    }}/>
                <Button tooltip={cadenas.VER_IMAGENES_DANIOS} tooltipOptions={{position: 'top'}} type="button"
                    icon="pi pi-image" className="p-button-success" style={{marginRight: '.5em'}}
                    //disabled={rowData.danios === undefined || rowData.danios.length <= 0}
                    disabled={this.activarGaleria(rowData)}
                    onClick={(e) => {
                        this.showGaleriaDanios(rowData,this.state.viajeSeleccionado!.orderId,this.state.visitaSeleccionada!.concesionario);

                    }}/>    
            </div>;
        }
        const renderListDanio = (danio:any, layout:any) => {
            return (
                <div className="p-col-12">
                    <div style={{
                        padding: '1em',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div>
                            <div className="p-grid">
                                <div className="p-col-12">Area: <b>{danio.area}</b></div>
                                <div className="p-col-12">Nombre: <b>{danio.areaNombre}</b></div>
                                <div className="p-col-12">Tipo: <b>{danio.type}</b></div>
                                <div className="p-col-12">Nombre: <b>{danio.typeNombre}</b></div>
                                <div className="p-col-12">Severidad: <b>{danio.severity}</b></div>
                                <div className="p-col-12">Nombre: <b>{danio.severityNombre}</b></div>
                                <div className="p-col-12">Categoria: <b>{danio.category}</b></div>
                                <div className="p-col-12">Comentario: <b>{danio.comentario}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        const renderListFaltante = (faltante:any, layout:any) => {
            return (
                <div className="p-col-12">
                    <div style={{
                        padding: '1em',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div>
                            <div className="p-grid">
                                <div className="p-col-12">Código: <b>{faltante.code}</b></div>
                                <div className="p-col-12">Nombre: <b>{faltante.nombre}</b></div>
                                <div className="p-col-12">Comentario: <b>{faltante.comentario}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        const obtenerNombreSiniestro = () =>{
            var motivoSiniestro = JSON.parse(this.state.chasisSelected.siniestro)
            return motivoSiniestro.nombre;
        }
        return (
          <div className="p-grid">
            <div className="p-col-12">
              <DataTable
                value={this.state.chasis}
                paginator={true}
                rows={NUM_ROWS_POR_PAGINA}
                header={headerDatatable}
                ref={this.dataTableRefChasis}
                responsive={true}
              >
                <Column field="vin" header={cadenas.CAPTURA_ONLINE_VINS_DATA_VIN} filter={true} sortable={true} />
                <Column field="model" header={cadenas.CAPTURA_ONLINE_VINS_DATA_MODELO} filter={true} sortable={true} />
                <Column
                  field="modelsize"
                  header={cadenas.CAPTURA_ONLINE_VINS_DATA_TAMANIO}
                  filter={true}
                  sortable={true}
                />
                <Column field="color" header={cadenas.CAPTURA_ONLINE_VINS_DATA_COLOR} filter={true} sortable={true} />
                <Column
                  field="estadoChasis"
                  header={cadenas.CAPTURA_ONLINE_VINS_DATA_ESTATUS}
                  filter={true}
                  sortable={true}
                />
                <Column body={actionDatatable} style={{ textAlign: 'center', width: '15em' }} />
              </DataTable>
            </div>

            <Dialog
              header={this.state.chasisSelected?.vin}
              visible={this.state.dialogInfoVisible}
              position="center"
              blockScroll={true} //footer={footerDialogSiniestro} //maximized={true}
              style={{ width: '60vw' }}
              modal={true}
              onHide={() => this.setState({ dialogInfoVisible: false })}
            >
              <div className="p-fluid">
                <div className="p-field p-grid">
                  {this.state.chasisSelected?.siniestrado || (this.state.chasisSelected?.siniestro != undefined && this.state.chasisSelected?.siniestro != "") ? (
                    <div className="p-col-12">
                      <div
                        style={{
                          padding: '1em',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <div className="p-grid">
                            <div className="p-col-12">Vin Siniestrado</div>
                            <div className="p-col-12">
                              Motivo: <b>{obtenerNombreSiniestro()}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <TabView
                      activeIndex={this.state.indexActivo}
                      onTabChange={(e) => this.setState({ indexActivo: e.index })}
                    >
                      <TabPanel header="Daños">
                        {this.state.chasisSelected?.danios !== undefined &&
                        this.state.chasisSelected?.danios !== "[]" ? (
                          <DataView
                            value={JSON.parse(this.state.chasisSelected?.danios)}
                            layout="list"
                            //header={header}
                            itemTemplate={renderListDanio}
                          />
                        ) : (
                          <div>
                            <p>No hay daños</p>
                          </div>
                        )}
                      </TabPanel>
                      <TabPanel header="Faltantes">
                        {this.state.chasisSelected?.faltantes !== undefined &&
                        this.state.chasisSelected?.faltantes !== "[]" ? (
                          <DataView
                            value={JSON.parse(this.state.chasisSelected?.faltantes)}
                            layout="list"
                            //header={header}
                            itemTemplate={renderListFaltante}
                          />
                        ) : (
                          <div>
                            <p>No hay faltantes</p>
                          </div>
                        )}
                      </TabPanel>
                    </TabView>
                  )}
                </div>
              </div>
            </Dialog>
            <Dialog
              header="Galeria daños"
              visible={this.state.dialogGaleriaVisible}
              position="center"
              blockScroll={true} //footer={footerDialogSiniestro} maximized={true}
              style={{ width: '50vw' }}
              modal={true}
              onHide={() => this.setState({ dialogGaleriaVisible: false, images: [] })}
            >
              <div>
                <div>
                  {this.state.images.length <= 0 ? (
                    <div className="card">
                      <Card>
                        <div className="p-text-nowrap p-text-truncate p-text-bold">
                          No se agregaron imagenes de daños para este vin: {this.state.chasisSelected?.vin}
                        </div>
                      </Card>
                    </div>
                  ) : (
                    <div className="card">
                      <Galleria
                        value={this.state.images}
                        responsiveOptions={this.state.responsiveOptions}
                        numVisible={3}
                        circular
                        style={{ maxWidth: '800px' }}
                        //thumbnailItemTemplate={this.thumbnailTemplate}
                        previewItemTemplate={this.itemTemplate}
                        showThumbnails={false}
                        showIndicators={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Dialog>
          </div>
        );
    }

    /**
     * Se modifica el state para controlar la visibilidad de los 
     * datatables.
     * @param {string} vistaActual Nombre de la vista actual que observa el usuario.
     */
    cambiarVista(vistaActual: string) {
        switch (vistaActual) {
            case CONSULTA_VIAJES.VISTA_VISITAS:
                this.setState({
                    viajeSeleccionado: undefined,
                });
            break;
            case CONSULTA_VIAJES.VISTA_CHASIS:
                this.setState({
                    visitaSeleccionada: undefined,
                });
            break;
        }
    }

    /**
     * Obtener pdf en servicio rest 
     * @param {any} rowData Instancia del row que pertenece al datatable. 
     */
     async obtenerPdf(rowData:any, orderId:String) {
        
        this.setState({blocking:true});
        let body = {
            orderId:orderId,
            codigoConcesionario: rowData.concesionario            
        }       
        console.log(body);
        console.log(PATHS_API.REPORTE_REV);

        console.log("Estatus entrega: "+rowData.estatusEntreg);
        
        await APIGATEWAYPDF.getFetch(PATHS_API.REPORTE_REV+"/"+orderId+"/"+rowData.concesionario+"/"+rowData.estatusEntrega)  
        .then((response) => response.blob())
        .then((blob) => {
            console.log('Armando pdf para descargar');
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'reporteEntrega.pdf');
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            document.body.removeChild(link);
            this.setState({
                 blocking:false                   
             });
             console.log('dEBERIA DESCARGAR en pdffff');   
        }) 
        .catch(error => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.ERROR_DESCARGA_REV);
            console.log('error en pdf', error);
       });       
    }
    /**
     * Cargar los registros de la página seleccionada en el datatable.
     * @param {any} event Evento de la página seleccionada.
     */
    generarReporteFull(df: any) {
        this.setState({
            blocking: true
        });
        const body = this.crearBodyBusqueda();
        body.daniosFaltantes = df;        
        console.log("Viajes -> Consulta -> danioFaltante", df)
        console.log("Viajes -> ConsultaDF -> body", JSON.stringify(body))
        APIGATEWAY.post(PATHS_API.REPORTE_FULL_MENSUAL, body )
        .then(response => {
            console.log("Viajes -> cargarPagina -> response", response)
            const link = document.createElement('a');
            link.href = response.urlReporte;
            //link.setAttribute('download', 'reporteEntrega.pdf');
            //3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            document.body.removeChild(link);
            this.setState({
                blocking: false,
               
            });
            console.log("Se descargo csv", response)
        })
        .catch(error => {
            this.setState({
                blocking:false                
            });
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VIAJES);
        });
    } 

    onModelFilterChange(event:any) {
        console.log("On filtros load event",event);
        this.setState({
            blocking: true
        });
        const body = this.crearBodyBusqueda();
        body.filtrosTabla = event.filters;        
        console.log("Viajes -> cargarPagina -> event", event)
        console.log("Viajes -> cargarPagina -> body", JSON.stringify(body))
        APIGATEWAY.post(PATHS_API.VIAJES_CONSULTA, body )
        .then(response => {
            console.log("Viajes -> cargarPagina -> response", response)
            this.setState({
                blocking: false,
                //pagina: response.pagina*NUM_ROWS_POR_PAGINA, // datatable usa como first, el num del row con cual inicia la pagina seleccionada
                viajes: response.datos
            });
        })
        .catch(error => {
            this.setState({
                blocking:false,
                viajes: []
            });
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_BUSQUEDA_ERROR_VIAJES);
        });
        //implementation goes here
    }
    activarGaleria(rowData:any){
        let activar = true;
        if (rowData.danios !== undefined){
            let dam = JSON.parse(rowData.danios)
            if(dam.length > 0){
                dam.forEach((d:any) => {
                    //if(d.comentario !== null){
                        activar = false;
                        return false;
                   // }
                });
            }
        }
        return activar;
    }
    async showGaleriaDanios(rowData:any,orderId: String, location: any) {
        this.setState({
            blocking: true,

        });
        var path = 'imagenes/' + orderId + '/' + location + '/' + rowData.vin + '/';
        console.log('path bucket', path);
        var numIma = 0;
        var claves:Array<any> = [];
        await Storage.list(path, {
            level: 'public'
        })
            .then(result => {
                console.log('listar bucket valor', result);
                numIma = result.length;
                claves=result;
                console.log('lista tamEncontrados', numIma);
            })
            .catch(err => {
                console.log('error al tratar de listar bucket', err);
            });
        var galeria:Array<any> = [];
        for (let clave of claves) {
            console.log('clave', clave);
            await Storage.get(clave.key, {
                level: 'public',
                download: false
            })
                .then(result => {
                    console.log('valor imagen', result);
                    galeria.push(result);
                })
                .catch(err => {
                    console.log('error al tratar obtner imagen bucket', err);
                });
        }
        this.setState({
            chasisSelected: rowData,
            images: galeria,
            dialogGaleriaVisible: true,
        });
        console.log('valor images',this.state.images)
        this.setState({
            blocking: false,

        });
    }

    async showGaleriaEvidencias(rowData:any,orderId: String) {
        this.setState({
            blocking: true,

        });
        var path = 'imagenes/' + orderId + '/' + rowData.concesionario + '/evidencias/';
        console.log('path bucket', path);
        var numIma = 0;
        var claves:Array<any> = [];
        await Storage.list(path, {
            level: 'public'
        })
            .then(result => {
                console.log('listar bucket valor', result);
                numIma = result.length;
                claves=result;
                console.log('lista tamEncontrados', numIma);
            })
            .catch(err => {
                console.log('error al tratar de listar bucket', err);
            });
        var galeria:Array<any> = [];
        for (let clave of claves) {
            console.log('clave', clave);
            await Storage.get(clave.key, {
                level: 'public',
                download: false
            })
                .then(result => {
                    console.log('valor imagen', result);
                    galeria.push(result);
                })
                .catch(err => {
                    console.log('error al tratar obtner imagen bucket', err);
                });
        }
        this.setState({
            //chasisSelected: rowData,
            images: galeria,
            dialogGaleriaEvidencias: true,
        });
        console.log('valor images',this.state.images)
        this.setState({
            blocking: false,

        });
    }

    async resendCode(concesionario: String, orderId: String) {
        this.setState({
            blocking: true,
        });
       
        //armar json a enviar
        var data = {          
        }

        //crear path
        var path = `/${orderId}/${concesionario}`;

        //post
        APIGATEWAY.post(PATHS_API.RESEND_CODE + path, data)
          .then(async response => {
            console.log("resendCode()", response);
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
          })
          .catch(async (error) => {
            this.setState({blocking:false});
            this.showError(cadenas.ERROR_TITULO, cadenas.CONSULTA_VIAJES_ERROR_RESEND);
            console.error("resendCode()",error);
          });
    }

    showDialogObservaciones(rowData:any) {
        console.log('row',rowData)
        var r = rowData.razonTermino === undefined ? '' : JSON.parse(rowData.razonTermino);
        this.setState({
            headerObservaciones: rowData.concesionario + ' - Observaciones',
            observacionesEntrega: rowData.observaciones,
            dialogObservaciones: true,
            razonTermino: r === '' ? '' : r.nombreEstado,
        });
    }

    /**
     * Función principal de react para renderizar los componentes html.
     */
    render() {
        const headerCard = <div style={{textAlign:"center"}}><h1>{cadenas.CONSULTA_VIAJES_VISTA_TITUTLO}</h1></div>;
       
        return  (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking}>
                <Growl ref={this.growlError} />
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Card header={headerCard} style={{padding:"1em"}}>
                                <div className="p-grid">
                                    {this.state.viajeSeleccionado === undefined &&
                                        this.crearFormularioBusqueda()
                                    }

                                    <div className="p-col-12">
                                    {(this.state.viajeSeleccionado === undefined && 
                                        getUsuarioLogueado()?.rol != ROLES.ADMINNSO) && this.dataTableViajes() 
                                    }

                                    {(this.state.viajeSeleccionado === undefined &&
                                        getUsuarioLogueado()?.rol === ROLES.ADMINNSO) && this.dataTableViajesAdminNso()
                                    }
                                    
                                    
                                    {this.state.viajeSeleccionado !== undefined && 
                                    this.state.visitaSeleccionada === undefined &&
                                        this.dataTableVisitas()
                                    }

                                    {this.state.visitaSeleccionada !== undefined &&
                                        this.dataTableChasis()
                                    }
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default Viajes;
