import { USER_USUARIO, 
        AUTH_USER_TOKEN_KEY, 
        USER_GROUPS, VIAJES_CAPTURA } from './constants';
import { UsuarioInterface } from '../Interfaces/Usuario.interface';

/**
 * Definir el objeto del viaje.
 */
interface ViajeEncontrado {
    [key: string]: [any];
}

/**
 * Obtener la información del usuario logueado
 * que se encuentra en session storage.
 */
export function getUsuarioLogueado() {
    let usuarioLogueado = sessionStorage.getItem(USER_USUARIO);
    let tokenUsuario = sessionStorage.getItem(AUTH_USER_TOKEN_KEY);
    if (null !== usuarioLogueado && null !== tokenUsuario) {
        let usuarioLogueadoJson = JSON.parse(usuarioLogueado);
        var rol = usuarioLogueadoJson.rol;
        var tipo = "";
        if (rol.includes("ADMIN_SISTEMA_")){
            tipo = rol.substring(rol.indexOf('ADMIN_SISTEMA_') + 14, rol.length);
            rol = rol.substring(0, rol.indexOf('ADMIN_SISTEMA_') + 13);
        }

        let usuario: UsuarioInterface = {
            id: usuarioLogueadoJson.id,
            nombreCompleto: usuarioLogueadoJson.nombreCompleto,
            correo: usuarioLogueadoJson.correo,
            telefono: usuarioLogueadoJson.telefono,
            usuario: usuarioLogueadoJson.usuario,
            rol: rol,
            rutasAcceso: usuarioLogueadoJson.detalleRol.rutasAcceso,
            token: tokenUsuario,
            numDealer: '',
            numTransportista: '',
            codigoEmpresa: usuarioLogueadoJson.codigoEmpresa,
            tipoadmin: tipo
        }
        return usuario;
    } 
    else {
        return undefined;
    }
}

/**
 * Establecer la información del usuario logueado
 * en session storage.
 */
export function setUsuarioLogueado(usuario:any) {
    sessionStorage.setItem(USER_USUARIO, JSON.stringify(usuario));
}

/**
 * Establecer el token del usuario en session storage.
 */
export function setTokenUsuario(accessToken:any){
    sessionStorage.setItem(AUTH_USER_TOKEN_KEY, accessToken.getJwtToken());
}

/**
 * Establecer el token del usuario en session storage(octagon).
 */
export function setTokenOctagonUsuario(token:any){
    sessionStorage.setItem(AUTH_USER_TOKEN_KEY, token);
}

/**
 * Establecer el grupo al que pertenece el usuario en session storage.
 */
export function setGruposUsuario(payload:any){
    sessionStorage.setItem(USER_GROUPS, payload['cognito:groups']);
}

/**
 * Establecer los viajes que se usan en la pantalla REV - Captura Online
 * en session storage.
 */
export function setViajesParaCaptura(usuario: string, viajeEncontrado: any) {
    let viajesCapturaJson:ViajeEncontrado = {};
    if (null === getViajesParaCaptura(usuario)) {
        viajesCapturaJson[usuario] = [viajeEncontrado];
    } else{
        let viajesSession = getViajesParaCaptura(usuario);
        viajesSession.push(viajeEncontrado);
        viajesCapturaJson[usuario] = viajesSession;
    }
    sessionStorage.setItem(VIAJES_CAPTURA, JSON.stringify(viajesCapturaJson));
}
/**
 * Obtener los viajes que se usan en la pantalla REV - Captura Online
 * que se encuentra en session storage.
 */
export function getViajesParaCaptura(usuario: string) {
    let viajesCaptura = sessionStorage.getItem(VIAJES_CAPTURA);
    if (null != viajesCaptura){
        let viajesCapturaJson = JSON.parse(viajesCaptura);
        return viajesCapturaJson[usuario];
    } else {
        return null;
    }
}
