import * as React from 'react';
import classNames from 'classnames';
import { Switch, Route } from "react-router-dom";

/**
 * Componentes del sistema.
 */
import AppTopBar from '../../Components/Layout/AppTopBar';
import AppMenu from '../../Components/Layout/AppMenu';
import InfoDashboard from './InfoDashboard';
import { ROLES, ROUTES } from '../../Utils/constants';
import Transportistas from '../Catalogos/Transportistas';
import Concesionarios from '../Catalogos/Concesionarios';
import ViajesEnCurso from '../Consultas/ViajesEnCurso';
import ViajesEntregados from '../Consultas/ViajesEntregados';
import AdministradorSistema from '../Usuarios/AdministradorSistema';
import AdministradorDealer from '../Usuarios/AdministradorDealer';
import { getUsuarioLogueado } from '../../Utils/gestionSessionStorage';
import Inspector from '../Usuarios/Inspector';
import AdminTransportista from '../Usuarios/AdminTransportista';
import Conductor from '../Usuarios/Conductor';
import Viajes from '../Consultas/Viajes';
import Bitacora from '../Consultas/Bitacora';
import Emergencia from '../Rev/Emergencia';
import logo from '../../Themes/assets/images/logo-vwm.png';
import NuevoCodigo from '../Consultas/NuevoCodigo';
import UsoApp from '../Consultas/UsoApp';
import Correos from '../Usuarios/Correos';

/**
 * Definir las propiedades que recibe el componente.
 */
interface Props {
}

/**
 * Definir el estado del componente.
 */
interface State {
    layoutMode: string;
    layoutColorMode: string;
    overlayMenuActive: boolean;
    staticMenuInactive: boolean;
    mobileMenuActive: boolean;
}

/**
 * @class
 * Componente que permite estructurar todos
 * los componentes del sistema.
 */
class DashboardContainer extends React.Component<Props, State> {
    menu: object[];
    menuClick: boolean;

    /**
     * @constructor
     * Inicializar el estado del componente.
     * @param {any} props Propiedades generadas
     */
    constructor(props: any) {
        super(props);
        this.menu = [];
        this.menuClick = false;
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false
        };
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.createMenu();
    }

    /**
     * Asignar las opciones del menú dependiendo del
     * usuario logueado.
     */
    createMenu() {
        var user = getUsuarioLogueado();
        if (user != undefined && user != null){
            console.log("logueado", getUsuarioLogueado());
            if (user?.rol == ROLES.ADMINSIS){
                var accesos = user?.rutasAcceso!;
                var accesos2 = accesos[2] as any;
                
                if (user?.tipoadmin == "NSO"){
                  //NSO
                  let filteredArray = accesos2.items.filter((item: any) => item.label !== 'Transportista');
                  accesos2.items = filteredArray;
                  console.log('menus', accesos);
                  this.menu = accesos;
                }
                else if (user?.tipoadmin == "LOGISTICA"){ //TRANSPORTISTA
                    let filteredArray = accesos2.items.filter((item: any) => item.label !== 'Concesionaria');
                    accesos2.items = filteredArray;
                    console.log('menus', accesos);
                    this.menu = accesos;
                }
                else { //GENERAL
                    this.menu = accesos;
                }
            } else
            {
                this.menu = user?.rutasAcceso!;
            }
        }
    }

    /**
     * Validar en que dispositivo se visualiza el sistema.
     */
    isDesktop() {
        return window.innerWidth > 1024;
    }

    /**
     * Definir el comportamiento del menú de acuerdo a la
     * resolución de la pantalla donde se visualiza el sistema.
     * @param {any} event Evento cuando se selecciona el menú.
     */
    onToggleMenu(event: any) {
        this.menuClick = true;
        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
        event.preventDefault();
    }

    /**
     * Asignar el valor de la propiedad menuClick cuando se selecciona
     * el icono de barras en el menú superior del sistema.
     * @param {any} event Evento click del icono.
     */
    onSidebarClick(event: any) {
        this.menuClick = true;
    }

    /**
     * Definir acción cuando se selecciona una opción del menú.
     * @param {any} event Evento click de la opción seleccionada.
     */
    onMenuItemClick(event: any) {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    /**
     * @function
     * Función principal para renderizar el contenido del componente.
     */
    render() {
        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });

        return (
          <div className={wrapperClass}>
            <AppTopBar onToggleMenu={this.onToggleMenu} />

            <div className={sidebarClassName} onClick={this.onSidebarClick}>
              <div className="layout-logo">
                <img alt="VWM" src={logo} />
              </div>
              <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
              <div>
                <p>Build: {process.env.REACT_APP_VERSION}</p>
              </div>
            </div>

            <div className="layout-main">
              {/* definir esta rutas igual en el archivo Routes.tsx */}
              <Switch>
                <Route path={ROUTES.DASHBOARD} component={InfoDashboard} />
                {/* catalogos */}
                <Route path={ROUTES.TRANSPORTISTAS} component={Transportistas} />
                <Route path={ROUTES.CONCESIONARIOS} component={Concesionarios} />
                {/* catalogos */}
                <Route path={ROUTES.VIAJES} component={Viajes} />
                <Route path={ROUTES.ENCURSO} component={ViajesEnCurso} />
                <Route path={ROUTES.ENTREGADOS} component={ViajesEntregados} />
                <Route path={ROUTES.BITACORA} component={Bitacora} />
                <Route path={ROUTES.USO_APP} component={UsoApp} />

                <Route path={ROUTES.NUEVOCODIGO} component={NuevoCodigo} />
                {/* rev */}
                <Route path={ROUTES.REV_EMERGENCIA} component={Emergencia} />
                {/* usuarios */}
                <Route path={ROUTES.USUARIOS_ADMINSIS} component={AdministradorSistema} />
                <Route path={ROUTES.USUARIOS_ADMINDEALER} component={AdministradorDealer} />
                <Route path={ROUTES.USUARIOS_INSPECTOR} component={Inspector} />
                <Route path={ROUTES.USUARIOS_ADMINTRANS} component={AdminTransportista} />
                <Route path={ROUTES.USUARIOS_CONDUCTOR} component={Conductor} />
                <Route path={ROUTES.CORREOS} component={Correos} />
              </Switch>
            </div>

            {/* <AppFooter /> */}
            <div className="layout-mask"></div>
          </div>
        );
    }
}

export default DashboardContainer;
