import React, { Component, createRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import BlockUi from 'react-block-ui';
import { Growl } from 'primereact/growl';

import { cadenas } from '../../Utils/cadenas';
import { PATHS_API, ROLES, NUM_ROWS_POR_PAGINA, CONFIG_GROWL, 
    EDOS_COGNITO, MILLIS_RESET_DISABLED } from '../../Utils/constants';
import FormularioRegistro from '../../Components/FormularioRegistro';
import {APIGATEWAY} from '../../Api/ApiGateway';
import { UsuarioInterface } from '../../Interfaces/Usuario.interface';

/** Definición del estado para el componente. */
interface State {
  usuarios: Array<{}>;
  dialogVisible: boolean;
  id: string;
  nombreCompleto: string;
  correo: string;
  usuario: string;
  blocking: boolean;
  [key: string]: any;
  tituloDialog: string;
  labelBtnAccion: string;
  rolAdminSysAdminReadList: Array<{}>;
  rolAdminSysAdminReadSeleccionado: string;
  tiporolAdminSysAdminReadSeleccionado: string;
  tiporolAdminSysAdminReadList: Array<{}>;
}

/**
 * @class
 * Componente de tipo clase para mostrar la pantalla donde se realiza
 * la administración de los usuarios de tipo ADMIN_SISTEMA.
 */
class AdministradorSistema extends Component<{}, State> {
    private dataTable: any;
    private growlNotificacion: any;
    constructor(props:any) {
        super(props);
        this.dataTable = createRef() 
        this.growlNotificacion = createRef() 
        this.state = {
          usuarios: [],
          dialogVisible: false,
          id: '',
          nombreCompleto: '',
          correo: '',
          usuario: '',
          blocking: false,
          tituloDialog: '',
          labelBtnAccion: '',
          rolAdminSysAdminReadList: [],
          rolAdminSysAdminReadSeleccionado: '',
          tiporolAdminSysAdminReadSeleccionado: '',
          tiporolAdminSysAdminReadList: [],
        };
        this.export = this.export.bind(this);
        this.limpiarFormulario = this.limpiarFormulario.bind(this);
        this.validarRegistro = this.validarRegistro.bind(this);
        this.registrar = this.registrar.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.settearState = this.settearState.bind(this);
        this.obtenerAdministradoresSistema = this.obtenerAdministradoresSistema.bind(this);
        this.showDialogNuevo = this.showDialogNuevo.bind(this);
        this.activarDesactivar = this.activarDesactivar.bind(this);
        this.resetear = this.resetear.bind(this);
        this.setRolAdminSysAdminReadList = this.setRolAdminSysAdminReadList.bind(this);
        this.setTipoRolAdminSysAdminReadList = this.setTipoRolAdminSysAdminReadList.bind(this);
    }

    /** 
     * Limpiar las variables de estado. 
     * */
    limpiarFormulario() {
        this.setState({
            id:'',
            nombreCompleto:'',
            correo:'',
            usuario:'',
            rol:'',
            rolAdminSysAdminReadSeleccionado: ''
        });
    }

    /** 
     * Función para acceder al valor de los inputs 
     * del componente FormularioRegistro.
     */
    settearState(id:string, value:any) {
        this.setState({[id]:value});
    }

    /**
     * Se ejecuta antes de renderizar el componente.
     */
    componentDidMount() {
        this.setState({blocking:true});
        this.obtenerAdministradoresSistema();  
        this.setRolAdminSysAdminReadList(); 
        this.setTipoRolAdminSysAdminReadList(); 
    }

    /**
     * Obtener los usuarios de tipo ADMIN_SISTEMA.
     */
    obtenerAdministradoresSistema() {
        const body = {
            usuario: "*"
        }
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/leer", body)
        .then(response => {
            console.log("AdministradorSistema -> obtenerAdministradoresSistema -> response", response)
            this.setState({
                    usuarios: response,
                    blocking:false
                });
        })
        .catch(error => {
            console.log("AdministradorSistema -> obtenerAdministradoresSistema -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ADMINSIS_ERROR_OBTENER_USUARIOS);
        });
    }

    setRolAdminSysAdminReadList(){
        this.setState({
            rolAdminSysAdminReadList: [{value:ROLES.ADMINSIS,label:ROLES.ADMINSIS_LABEL},
                {value:ROLES.ADMINREAD,label:ROLES.ADMINREAD_LABEL},
                {value:ROLES.SEGUROS,label:ROLES.SEGUROS_LABEL},
                {value:ROLES.ADMINNSO,label:ROLES.ADMINNSO_LABEL}
            ]
        });
    }

    setTipoRolAdminSysAdminReadList(){
        this.setState({
          tiporolAdminSysAdminReadList: [
            { value: 'NSO', label: 'NSO' },
            { value: 'LOGISTICA', label: 'LOGISTICA' },
            { value: '', label: 'GENERAL' },
          ],
        });
    }
     

    /**
     * Validar el registro del usuario.
     * @param {React.FormEvent} event Evento submit del formulario.
     */
    validarRegistro(event:React.FormEvent) {
        event.preventDefault();        
        let body: UsuarioInterface = {
          nombreCompleto: this.state.nombreCompleto,
          correo: this.state.correo,
          usuario: this.state.usuario,
          rol: this.state.rolAdminSysAdminReadSeleccionado,
          codigoEmpresa: '',
          tipoadmin: this.state.tiporolAdminSysAdminReadSeleccionado,
        };
        this.setState({blocking:true});
        APIGATEWAY.post(PATHS_API.VALIDAR_USUARIO_SIGNUP, body)
        .then(usuarioValidado => {
            console.log("AdministradorSistema -> validarRegistro -> usuarioValidado", usuarioValidado)
            usuarioValidado.tipoadmin = body.tipoadmin;
            this.registrar(usuarioValidado);
        })
        .catch(error => {
            console.log("AdministradorSistema -> validarRegistro -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, error.response.data);
        });
    }

    /**
     * Registro del usuario.
     * @param {any} body Información del usuario para registrar.
     */
    registrar(body:any) {
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/crear", body)
        .then(() => {
            this.limpiarFormulario();
            this.setState({
                    blocking:false,
                    dialogVisible:false
                });
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.USUARIO_REGISTRADO+" "+body.correo);
            this.obtenerAdministradoresSistema();
        })
        .catch(error => {
            this.limpiarFormulario();
            console.log("AdministradorSistema -> registrar -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, error.response.data);
            //this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, JSON.stringify(error));
        });
    }

    /**
     * Eliminar el usuario.
     * @param {any} rowData Instancia del row que pertenece al datatable. 
     */
    eliminar(rowData:any) {
        this.setState({blocking:true});
        let body = {
            usuario: rowData.usuario
        };
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/borrar", body)
        .then(() => {
            this.setState({
                    blocking:false,
                    dialogVisible:false
                });
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
            this.obtenerAdministradoresSistema();
        })
        .catch(error => {
            console.log("AdministradorSistema -> eliminar -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ADMINSIS_ERROR_ELIMINACION);
        });
    }

    /**
     * Activar y desactivar según sea el caso del usuario de acuerdo a su 
     * propiedad activo.
     * @param {any} rowData Instancia del row que pertenece al datatable.
     */
    activarDesactivar(rowData:any) {
        const body = {
            usuario: rowData.usuario
        };
        const path = rowData.activo ? PATHS_API.USUARIOS_API+"/desactivar" : PATHS_API.USUARIOS_API+"/activar";
        APIGATEWAY.post(path, body)
        .then(response => {
            console.log("AdministradorSistema -> activarDesactivar -> response", response)
            this.obtenerAdministradoresSistema();
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
        })
        .catch(error => {
            console.log("AdministradorSistema -> activarDesactivar -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ACCION_NO_EXITOSA);
        });        
    }
    
    /**
     * Resetear la contraseña del usuario.
     * @param {any} rowData Instancia del row que pertenece al datatable.
     */
    resetear(rowData:any) {
        const body = {
            usuario: rowData.usuario
        };
        APIGATEWAY.post(PATHS_API.USUARIOS_API+"/reset", body)
        .then(response => {
            console.log("AdministradorSistema -> resetear -> response", response)
            this.obtenerAdministradoresSistema();
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.ACCION_EXITOSA);
            this.showNotificacion(cadenas.GROWL_SUCCESS, cadenas.EXITO_TITUTLO, cadenas.USUARIO_RESET+" "+rowData.correo);
        })
        .catch(error => {
            console.log("AdministradorSistema -> resetear -> error", error)
            this.setState({blocking:false});
            this.showNotificacion(cadenas.GROWL_ERROR, cadenas.ERROR_TITULO, cadenas.ACCION_NO_EXITOSA);
        });     
    }

    /**
     * Mostrar la notificación de tipo Growl (componente del framewrok Prime React).
     * @param {string} type Tipo de la notificación. 
     * @param {string} title Título de la notificación.
     * @param {string} message Mensaje de la notificación.
     */
    showNotificacion(type:string, title:string, message:string) {
        this.growlNotificacion.current.show({severity: type, summary: title, detail: message, life:CONFIG_GROWL.LIFE});
    }

    /**
     * Exportar los datos del datatable a formato CSV.
     * @param {any} dataTable Referencia del datatable.
     */
    export() {
        this.dataTable.current.exportCSV();
    }

    /**
     * Desactivar o activar boton de reset para cada usuario
     */
    activarReset(rowData:any) {
        let activar = true;
        if(rowData.activo){
            if(rowData.estado === EDOS_COGNITO.RESET){
                var diff = (new Date().getTime()) - rowData.fechaUltimoReset;
                if(diff > MILLIS_RESET_DISABLED){   
                    activar = false;              
                }
            }else if (rowData.estado !== EDOS_COGNITO.CREADO) {
                activar = false;
            }
            
        }
        return activar;
    }

    /**
     * Mostrar el dialog para registrar usuario.
     */
    showDialogNuevo() {
        this.limpiarFormulario();
        this.setState({
            dialogVisible: true, 
            tituloDialog: cadenas.ADMINSIS_DIALOG_NUEVO_TITULO,
            labelBtnAccion: cadenas.BTN_GUARDAR
        });
    }

    /**
     * @function
     * Función principal para renderizar el contenido del componente.
    */
    render() {
        const headerDatatable = <div className="p-grid">
            <div className="p-col-6" style={{textAlign:"left"}} >
                <Button className="p-button p-button-rounded"  label={cadenas.ADMINSIS_BTN_NUEVO}
                    onClick={this.showDialogNuevo} />
            </div>
            <div className="p-col-6" style={{textAlign:"right"}} >
                <Button type="button" icon="pi pi-external-link" iconPos="left" label={cadenas.BTN_EXPORTAR_CSV} onClick={this.export} />
            </div>
        </div>
        const headerCard = <div style={{textAlign:"center"}}><h1>{cadenas.ADMINSIS_VISTA_TITULO}</h1></div>;
        const footerDialog = (
            <div>
                <Button className="p-button p-button-success" label={this.state.labelBtnAccion} icon="pi pi-check" type="submit" />
                <Button type="button" className="p-button p-button-danger" label={cadenas.BTN_CANCELAR} icon="pi pi-times" onClick={(e) => {this.limpiarFormulario(); this.setState({dialogVisible: false});}} />
            </div>
        );
        const actionDatatable = (rowData:any, column:any) => {
            return <div>
                <Button tooltip={rowData.activo ? cadenas.ADMINSIS_BTN_DESACTIVAR : cadenas.ADMINSIS_BTN_ACTIVAR} 
                    tooltipOptions={{position: 'top'}} type="button"
                    disabled={rowData.estado === EDOS_COGNITO.RESET || rowData.estado === EDOS_COGNITO.CREADO}
                    icon={rowData.activo ? "pi pi-user-minus"  : "pi pi-user-plus"}
                    className={rowData.activo ? "p-button-danger" : "p-button-success"} 
                    style={{marginRight: '.5em'}} 
                    onClick={(e) => {
                        if (window.confirm(cadenas.CONFIRMAR_ACCION)) {
                            this.activarDesactivar(rowData);
                        }
                    }}/>
                <Button tooltip={cadenas.ADMINSIS_BTN_RESET} tooltipOptions={{position: 'top'}} type="button" 
                    icon="pi pi-key" className="p-button-success" style={{marginRight: '.5em'}} 
                    //disabled={rowData.estado === EDOS_COGNITO.RESET || !rowData.activo}
                    disabled={this.activarReset(rowData)}
                    onClick={(e) => {
                        if (window.confirm(cadenas.CONFIRMAR_ACCION)) {
                            this.resetear(rowData);
                        }
                    }}/>
                <Button tooltip={cadenas.BTN_ELIMINAR} tooltipOptions={{position: 'top'}} type="button" 
                    icon="pi pi-trash" className="p-button-warning"
                    onClick={(e) => {
                        if (window.confirm(cadenas.CONFIRMAR_ACCION)) {
                            this.eliminar(rowData);
                        }
                    }} />
            </div>;
        }
        return  (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking}>
                <Growl ref={this.growlNotificacion} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <Card header={headerCard} style={{padding:"1em"}}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <DataTable value={this.state.usuarios} paginator={true} rows={NUM_ROWS_POR_PAGINA}
                                        header={headerDatatable} responsive={true}
                                        ref={this.dataTable} 
                                        emptyMessage={cadenas.DATATABLE_VACIO}>
                                        <Column field="nombreCompleto" header={cadenas.ADMINSIS_DATA_NOMBRE_COMPLETO} filter={true} sortable={true} />
                                        <Column field="usuario" header={cadenas.ADMINSIS_DATA_USUARIO} filter={true} sortable={true} />
                                        <Column field="correo" header={cadenas.ADMINSIS_DATA_CORREO} filter={true} sortable={true} />
                                        <Column field="rol" header={cadenas.ADMINSIS_DATA_ROL} filter={true} sortable={true} />
                                        <Column field="codigoEmpresa" header={cadenas.ADMINSIS_DATA_COD_EMPRESA} filter={true} sortable={true} />
                                        <Column field="estado" header={cadenas.ADMINSIS_DATA_ESTADO} filter={true} sortable={true} />
                                        <Column body={(rowData:any, column:any) => rowData.activo ? cadenas.ACTIVO_SI : cadenas.ACTIVO_NO} header={cadenas.ADMINSIS_DATA_ACTIVO} style={{width: '5em'}}/>
                                        <Column  body={actionDatatable} style={{textAlign:'center', width: '10em'}}/>
                                    </DataTable>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <form onSubmit={this.validarRegistro}>
                        <Dialog header={this.state.tituloDialog} visible={this.state.dialogVisible} position="center"
                            blockScroll={true} footer={footerDialog}
                            style={{width: '50vw'}} modal={true} onHide={() => {this.limpiarFormulario(); this.setState({dialogVisible: false});}}>
                            <FormularioRegistro settearState={this.settearState} stateParent={this.state} />
                        </Dialog>
                    </form>
                </div>
            </BlockUi>
        </div>
        );
    }
}

export default AdministradorSistema;
