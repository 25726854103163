import { Storage } from 'aws-amplify';
import { getUsuarioLogueado } from '../Utils/gestionSessionStorage';
import { ROLES } from '../Utils/constants';
/**
 * Función para abrir el documento de ayuda.
 */
export default async (location: String) => {
    var path = 'manuales';
    console.log('ddd:' + location);
    var user = getUsuarioLogueado();
    path = path + '/';
    switch (user?.rol) {
      case ROLES.ADMINUMS:
        path = path + 'Ums';
        break;
      case ROLES.ADMINSIS:
        if (user?.tipoadmin == 'NSO') {
          path = path + 'AdminSisNso';
        } else if (user?.tipoadmin == 'LOGISTICA') {
          path = path + 'AdminSisLog';
        } else {
          path = path + 'AdminSis';
        }
        break;
      case ROLES.ADMINDEALER:
        path = path + 'AdminDealer';
        break;
      case ROLES.ADMINTRANSPORTISTA:
        path = path + 'AdminTrans';
        break;
      case ROLES.INSPECTOR:
        path = path + 'Inspector';
        break;
      case ROLES.ADMINNSO:
        path = path + 'UsuarioNSO';
        break;
      case ROLES.CONDUCTOR:
        path = path + 'Conductor';
        break;
      case ROLES.SEGUROS:
        path = path + 'Seguros';
        break;
      case ROLES.ADMINREAD:
        path = path + 'AdminRead';
        break;
      default:
        path = path;
        break;
    }


    if (location == "/dashboard"){
        path = path + '/';
        switch (user?.rol) {
          case ROLES.ADMINUMS:
            path = path + 'Administradores' + '.pdf';
            break;
          case ROLES.ADMINSIS:
            if (user?.tipoadmin == 'NSO') {
              path = path + 'AdministradorSistemaNSO' + '.pdf';
            } else if (user?.tipoadmin == 'LOGISTICA') {
              path = path + 'AdministradorSistemaLogistica' + '.pdf';
            } else {
              path = path + 'AdministradorSistema' + '.pdf';
            }
            break;
          case ROLES.ADMINDEALER:
            path = path + 'UsuarioAdministrador(Concesionario)' + '.pdf';
            break;
          case ROLES.ADMINTRANSPORTISTA:
            path = path + 'AdminTransportistas' + '.pdf';
            break;
          case ROLES.INSPECTOR:
            path = path + 'Inspector' + '.pdf';
            break;
          case ROLES.ADMINNSO:
            path = path + 'UsuarioNSO' + '.pdf';
            break;
          case ROLES.CONDUCTOR:
            path = path + 'Conductor' + '.pdf';
            break;
          case ROLES.SEGUROS:
            path = path + 'Seguros' + '.pdf';
            break;
          case ROLES.ADMINREAD:
            path = path + 'AdminRead' + '.pdf';
            break;
          default:
            path = path + location + '.pdf';
            break;
        }
    }
    else{
        path = path + location + '.pdf';   
    }
    // var path = 'manuales' + location + '.pdf';
    console.log('path bucket', path);
    var resultado;
    await Storage.get(path, {
        level: 'public',
        download: false
    })
    .then(result => {
        resultado = result;
    })
    .catch(err => {
        console.log('error al tratar de obtener bucket', err);
    });
    return  resultado;
}
